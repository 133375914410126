// /src/HomePage.js
import React from "react";
import "./HomePage.css";

function HomePage() {
  return (
    <div className="homePage">
      <div className="background">
        <div className="container">
          <div className="content">
            <h1>This Is Poetic Injustice</h1>
            <p>
              You can make poetry here using the Poetry Dispenser. It may not be
              very good poetry, but it usually meets the formal criteria for it.
              Sometimes the Poetry Dispenser gets the rhyme scheme or meter
              wrong. Click above on your desired poetry type, enter a short
              prompt, and click the Go button. You do not have to tell it to
              "write a poem about..." because it already knows that. Your prompt
              will elicit a poem.
            </p>
            <p>
              Be creative! The more descriptive you are, the better poem the
              Poetry Dispenser will dispense. If you're unsatisfied with the
              poem, you can say so in your next prompt and ask it to try again.
            </p>
            <p>
              If you dare to read the third worst poetry in the universe, try
              dispensing some Vogon poetry. If you really have a death wish,
              have a Vogon read it aloud to you.
            </p>
            {/* Add more content here as needed */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
