import React, { useState, useEffect } from "react";
import HomePage from "./HomePage";
import History from "./History";
import GptForm from "./GptForm";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
} from "react-router-dom";
import "./App.css";

function App() {
  useEffect(() => {
    const imagesToPreload = [
      "images/home-background.webp",
      "images/haiku-background.webp",
      "images/limerick-background.webp",
      "images/sonnet-background.webp",
      "images/villanelle-background.webp",
      "images/vogon-background.webp",
      // "images/history-background.webp",
      // Add paths to all images you want to preload
    ];

    imagesToPreload.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };
    // Add event listener
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isMenuOpen]);
  const handleCloseMenu = () => {
    // Example breakpoint check: adjust "768" based on your mobile breakpoint
    if (window.innerWidth <= 768 && isMenuOpen) {
      setIsMenuOpen(false);
    }
  };
  useEffect(() => {
    // Generate uniqueId once when the component mounts
    if (!localStorage.getItem("uniqueId")) {
      const id = generateUniqueId();
      localStorage.setItem("uniqueId", id);
      setUniqueId(id);
    } else {
      setUniqueId(localStorage.getItem("uniqueId"));
    }
  }, []);

  function generateUniqueId() {
    return "id-" + Math.random().toString(36).substr(2, 16);
  }

  return (
    <Router>
      <div className="App">
        <header>
          <nav>
            <div
              className="menu-icon"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <i className="fa-solid fa-bars"></i>
            </div>
            <ul className={isMenuOpen ? "nav-links open" : "nav-links"}>
              <li>
                <NavLink
                  to="/"
                  end
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/haiku"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Haiku
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/limericks"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Limericks
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/sonnets"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Sonnets
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/villanelles"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Villanelles
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/vogon"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Vogon
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/journal"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={handleCloseMenu}
                >
                  Journal
                </NavLink>
              </li>
            </ul>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<HomePage />} /> />
          <Route
            path="/haiku"
            element={<GptForm assistantType="Haiku" uniqueId={uniqueId} />}
          />
          <Route
            path="/limericks"
            element={<GptForm assistantType="Limericks" uniqueId={uniqueId} />}
          />
          <Route
            path="/sonnets"
            element={<GptForm assistantType="Sonnets" uniqueId={uniqueId} />}
          />
          <Route
            path="/villanelles"
            element={
              <GptForm assistantType="Villanelles" uniqueId={uniqueId} />
            }
          />
          <Route
            path="/vogon"
            element={<GptForm assistantType="Vogon" uniqueId={uniqueId} />}
          />
          <Route path="/journal" element={<History />} />
        </Routes>
        <div className="footer">
          <span>
            Copyright &copy;&nbsp;2024&nbsp;
            <a
              className="kineto-link"
              target="_blank"
              rel="noreferrer"
              href="https://kinetocreative.com"
            >
              Kineto Creative, LLC
            </a>
          </span>
        </div>
      </div>
    </Router>
  );
}

export default App;
